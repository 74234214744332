import React from 'react';
import Container from 'react-bootstrap/Container';
import Link from 'next/link';
import Constants from './Constants';
import { FiInstagram, FiTwitter } from 'react-icons/fi';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import UtilsManager from '../managers/UtilsManager';

class Footer extends React.Component {
  static defaultProps = {
    type: 'none',
  };

  constructor(props) {
    super(props);
    this.state = {
      type: props.type,
    };

    this.utilsManager = new UtilsManager();
  }

  componentDidMount() {
    const sessionStorage = window.sessionStorage;
    const context = sessionStorage.getItem('context');
    if (context) {
      this.setState({ type: context });
    }
  }

  onSocialClick(account) {
    var url = '';
    if (this.isSpacesFooter()) {
      if (account === 'twitter') {
        url = 'https://twitter.com/movementspaces';
      } else {
        url = 'https://www.instagram.com/movementspaces/';
      }
    } else {
      if (account === 'twitter') {
        url = 'https://www.twitter.com/eta.hq/';
      } else {
        url = 'https://www.instagram.com/eta.hq/';
      }
    }
    window.open(url);
  }

  isSpacesFooter() {
    return this.props.appDomain === 'movement' || this.props.type === 'spaces';
  }

  isETAFooter() {
    return this.props.appDomain === 'witheta' || this.props.type === 'curation';
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '200px',
          backgroundColor: Constants.lightGrayColor,
          alignItems: 'center',
        }}
      >
        {this.isSpacesFooter() && (
          <img
            src="/static/movement_black.png"
            style={{ height: 20, marginTop: 50, marginBottom: 10 }}
          />
        )}
        {!this.isSpacesFooter() && this.isETAFooter() && (
          <img
            src="/static/eta_black.png"
            style={{ height: 30, width: 80, marginTop: 50, marginBottom: 10 }}
          />
        )}
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            variant="light"
            onClick={() => this.onSocialClick('instagram')}
          >
            <FiInstagram size="20px" />
          </Button>
          <Button variant="light" onClick={() => this.onSocialClick('twitter')}>
            <FiTwitter size="20px" />
          </Button>
        </div>
        <p>Los Angeles, CA</p>
        <div style={{ display: 'flex' }}>
          <Link href="/blogs">
            <a>
              <small>Blog</small>
            </a>
          </Link>
          <Link href="privacy">
            <a style={{ marginLeft: 10 }}>
              <small>Privacy Policy</small>
            </a>
          </Link>
          <Link href="/terms">
            <a style={{ marginLeft: 10 }}>
              <small>Terms of Service</small>
            </a>
          </Link>
          <Link
            href={`mailto:info@${this.utilsManager.getDomainUrl(
              this.props.appDomain,
            )}`}
          >
            <a style={{ marginLeft: 10 }}>
              <small>Contact</small>
            </a>
          </Link>
          <Link href={`/sitemap`}>
            <a style={{ marginLeft: 10 }}>
              <small>Sitemap</small>
            </a>
          </Link>
        </div>
        {/* <p style={{color: 'gray'}}><small>© TheMovement, LLC 2022. All rights reserved</small></p> */}
        <p style={{ color: 'gray' }}>
          <small>© Outlet Tech LLC 2025. All rights reserved</small>
        </p>
      </div>
    );
  }
}

export default connect((state) => state)(Footer);
